import React from 'react';
import {Anchor, Col, Flex, Row} from 'antd';

const App: React.FC = () => {
    return (
        <Row>
            <Col span={8} className={"left-panel"}>
                <Flex gap={"middle"} align={"start"} style={{height: "100%"}} vertical>
                    <Anchor
                        style={{height: "100%", display: "flex", flexDirection: "row", justifyContent: "center"}}
                        items={[
                            {
                                key: 'who-am-i',
                                href: '#who-am-i',
                                title: 'WHO AM I?',
                                className: 'menu '
                            },
                            {
                                key: 'what-i-am-good-at',
                                href: '#what-i-am-good-at',
                                title: 'What I am good at?',
                                className: 'menu '
                            },
                            {
                                key: 'what-i-do-on-weekends',
                                href: '#what-i-do-on-weekends',
                                title: 'What I do on weekends?',
                                className: 'menu '
                            },
                            {
                                key: 'what-i-am-thriving',
                                href: '#what-i-am-thriving',
                                title: 'What I am thriving?',
                                className: 'menu '
                            },
                        ]}
                    />
                </Flex>
            </Col>
            <Col span={16} className={"right-panel"}>
                <section id="who-am-i" className={"panel"}>
                    <article className={"who-am-i"}>
                        <section>
                            <img className={"me-photo"} src={require("./assets/media/me.jpg")} width={500}
                                 height={500} alt={"me"}/>
                        </section>
                        <section className={"me-content"}>
                            <p>I am currently working as a <b>software engineer</b> based in <b>singapore</b>.</p>

                            <p>I graduated in <em><b>Cebu Philippines</b></em> with a Bachelors degree.</p>

                            <p>I work with different like minded people whose goal is to grow, innovate and advocate the
                                best
                                practices</p>
                        </section>


                    </article>
                </section>
                <section id="what-i-am-good-at" className={"panel"}>
                    <article>
                        <p>I am good at using the tools that I have learned through my entire career and use that to
                            solve and identify risk of the product</p>
                        <p>I understand how best practices works, the ETHOS of an engineer should focus. </p>
                        <p>
                            And having a single programming language or framework can limit your scope of solving
                            problems and creativity to broaden the possibility that a product can achieve in the long
                            run
                        </p>
                        <p>These are my tools:</p>
                        <h3>TypeScript / JavaScript</h3>
                        <p>Subsets: ReactJs, React Native, VueJs, Angular</p>
                        <h3>Python:</h3>
                        <p>Subsets: flask framework</p>
                        <p>Python Library Projects: https://pypi.org/manage/projects/</p>
                        <p>
                            <a href={"https://pypi.org/project/flask-app-core/"} target={"_blank"}>FLask App Core</a>
                            &nbsp; Flask Library that creates an API without router config compatible to <a
                            href={"https://flask.palletsprojects.com/en/3.0.x/"} target={"_blank"}>flask</a> and <a
                            href={"https://sanic.dev/en/"} target={"_blank"}>Sanic</a>&nbsp;Framework
                        </p>
                        <p>
                            <a href={"https://pypi.org/project/flask-blueprint/"} target={"_blank"}>Flask Blueprint</a>
                            &nbsp; Flask application that allows to create an API without router configuration
                        </p>
                        <p>
                            <a href={"https://pypi.org/project/mongoengine-serialize/"} target={"_blank"}>MongoDB engine
                                Serialize</a>
                            &nbsp; MongoDB json serializer
                        </p>
                        <h3>Kotlin:</h3>
                        <h3>Go:</h3>
                    </article>
                </section>
                <section id="what-i-do-on-weekends" className={"panel"}>
                    <article>
                        # This is an example of a markdown
                    </article>
                </section>
                <section id="what-i-am-thriving" className={"panel"}>
                    <article>
                        This is an example of a markdown
                    </article>
                </section>
            </Col>
        </Row>
    )
};

export default App;
